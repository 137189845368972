import React, { useState, useEffect } from "react";
import axios from "axios";
import "./AddTeamMembers.scss";

const API_URL = process.env.REACT_APP_API_URL;
const API_URL_IMAGES = process.env.REACT_APP_API_URL_IMAGES;

function AddTeamMembers() {
  const [fullname, setFullname] = useState("");
  const [designation, setDesignation] = useState("");
  const [category_id, setCategoryId] = useState("");
  const [image, setImage] = useState(null);
  const [categoriesList, setCategoriesList] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [editId, setEditId] = useState(null);

  useEffect(() => {
    fetchCategories();
    fetchTeamMembers();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${API_URL}/categories`);
      setCategoriesList(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
      alert("Failed to fetch categories. Please try again.");
    }
  };

  const fetchTeamMembers = async () => {
    try {
      const response = await axios.get(`${API_URL}/team`);
      setTeamMembers(response.data);
    } catch (error) {
      console.error("Error fetching team members:", error);
      alert("Failed to fetch team members. Please try again.");
    }
  };

  const handleFileChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("fullname", fullname);
    formData.append("designation", designation);
    formData.append("category_id", category_id);
    formData.append("image", image);

    try {
      if (editId) {
        await axios.put(`${API_URL}/team/${editId}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        alert("Team member updated successfully!");
      } else {
        await axios.post(`${API_URL}/team`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        alert("Team member added successfully!");
      }
      fetchTeamMembers();
      resetForm();
    } catch (error) {
      console.error("Error saving team member:", error);
      alert("Failed to save team member. Please try again.");
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${API_URL}/team/${id}`);
      fetchTeamMembers();
    } catch (error) {
      console.error("Error deleting team member:", error);
      alert("There was an error deleting the team member!");
    }
  };

  const handleEdit = (teamMember) => {
    setFullname(teamMember.fullname);
    setDesignation(teamMember.designation);
    setCategoryId(teamMember.category_id);
    setEditId(teamMember.id);
  };

  const resetForm = () => {
    setFullname("");
    setDesignation("");
    setCategoryId("");
    setImage(null);
    setEditId(null);
  };

  return (
    <div className="member-main-div">
      <div className="add-team-member">
        <h1 className="title-member">
          {editId ? "Update Team Member" : "Add Team Member"}
        </h1>
        <form onSubmit={handleSubmit}>
          <div>
            <label>Full Name:</label>
            <input
              className="input-members"
              type="text"
              value={fullname}
              onChange={(e) => setFullname(e.target.value)}
              placeholder="Full Name"
              required
            />
          </div>
          <div>
            <label>Designation:</label>
            <input
              className="input-members"
              type="text"
              value={designation}
              onChange={(e) => setDesignation(e.target.value)}
              placeholder="Designation"
              required
            />
          </div>
          <div>
            <label>Category:</label>
            <select
              className="select-add-member"
              value={category_id}
              onChange={(e) => setCategoryId(e.target.value)}
              required
            >
              <option value="" disabled>
                Select Category
              </option>
              {categoriesList.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label>Image:</label>
            <input type="file" onChange={handleFileChange} required />
          </div>
          <button className="button-add-team" type="submit">
            {editId ? "Update Team Member" : "Add Team Member"}
          </button>
        </form>
      </div>

      <div className="team-members-container">
        <h2 className="title-member-result">Team Members</h2>
        {teamMembers && teamMembers.length > 0 ? (
          teamMembers.map((teamMember) => (
            <div key={teamMember.id} className="team-member-card">
              <div className="team-member-info">
                <img
                  className="team-member-image"
                  src={`${API_URL_IMAGES}${teamMember.image}`}
                  alt={teamMember.fullname}
                />
                <div>
                  <span className="team-member-name">
                    {teamMember.fullname}
                  </span>
                  <span className="team-member-designation">
                    {teamMember.designation}
                  </span>
                  <span className="team-member-category">
                    {
                      categoriesList.find(
                        (cat) => cat.id === teamMember.category_id
                      )?.name
                    }
                  </span>
                </div>
              </div>
              <div className="team-member-actions">
                <button onClick={() => handleEdit(teamMember)}>Edit</button>
                <button onClick={() => handleDelete(teamMember.id)}>
                  Delete
                </button>
              </div>
            </div>
          ))
        ) : (
          <p>No team members found.</p>
        )}
      </div>
    </div>
  );
}

export default AddTeamMembers;
